"use client";
import Header from "@/components/Header";
import Layout from "@/components/Layout/Layout";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/navigation";

export default function NotFound() {
  const router = useRouter();
  return (
    <div className="flex justify-center items-center mx-auto h-screen my-auto">
      <div className="w-full text-center items-center justify-center flex h-100 flex-col">
        <div className="mb-15">
          <Image
            className="mx-auto mb-3"
            src="/images/logo/logo-icon.svg"
            alt="Logo"
            width={56}
            height={40}
          />
          <h2 className="font-bold text-black dark:text-white text-title-lg">
            <span className="text-primary">404</span> | Not Found
          </h2>
          <p className="font-semibold text-black dark:text-white text-lg mt-2">
            Could not find requested resource
          </p>
        </div>
        <div className="w-full lg:w-1/4 px-2">
          <div className="flex flex-col items-center w-full">
            <a
              className="flex w-full text-white items-center justify-center gap-3.5 rounded-lg border-0 bg-secondary p-3 hover:bg-opacity-50 dark:bg-primary dark:hover:bg-opacity-50"
              href="/"
            >
              Return Home
            </a>
          </div>
          <div className="relative flex py-4 items-center">
            <div className="flex-grow border-t border-gray-400"></div>
            <span className="flex-shrink mx-4 text-gray-400">or</span>
            <div className="flex-grow border-t border-gray-400"></div>
          </div>
          <div className="flex flex-col items-center w-full">
            <button
              id="sign-up-with-email"
              className="flex w-full text-white items-center justify-center gap-3.5 rounded-lg border-0 bg-secondary p-3 hover:bg-opacity-50 dark:bg-primary dark:hover:bg-opacity-50"
              onClick={(e) => {
                e.preventDefault();
                router.back();
              }}
            >
              Go back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
